import {
  Breakpoint,
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  type MobileAndDesktopSources,
} from '@farmersdog/corgi-x';

export function generateSrcSetString(src: string, size: number): string {
  return `${src} ${size}w`;
}

export function generateSourcesForPreloader({
  mobileSources,
  desktopSources,
}: MobileAndDesktopSources) {
  const mobileSrcSetStrings = [
    mobileSources.webP,
    mobileSources.jpg,
    mobileSources.png,
  ]
    .map(src => (src ? generateSrcSetString(src, Breakpoint.MD) : undefined))
    .filter(src => src !== undefined);

  const desktopSrcSetStrings = [
    desktopSources.webP,
    desktopSources.jpg,
    desktopSources.png,
  ]
    .map(src => (src ? generateSrcSetString(src, Breakpoint.LG) : undefined))
    .filter(src => src !== undefined);

  const mobileSourcesForPreloader = {
    imageSrcSet: mobileSrcSetStrings.join(', '),
    media: MOBILE_MEDIA_QUERY,
  };

  const desktopSourcesForPreloader = {
    imageSrcSet: desktopSrcSetStrings.join(', '),
    media: DESKTOP_MEDIA_QUERY,
  };

  return [mobileSourcesForPreloader, desktopSourcesForPreloader];
}
